const NODE_ENV = "";

export const baseUrl =
  NODE_ENV === "development"
    ? "http://localhost:8000/api"
    : "https://demopolaroidapi.addnectarstudio.com/api";

export const CLIENT_ID = NODE_ENV === "development"
            ? "cd773b87-a58a-4b07-a76c-217b353963de"
            : "cd773b87-a58a-4b07-a76c-217b353963de";

export const TENANT_ID = NODE_ENV === "development"
            ? "c011b2d2-894f-4ee2-b3c9-bb98ef38e0cd"
            : "c011b2d2-894f-4ee2-b3c9-bb98ef38e0cd";

export const GROUP_NAME = "Polaroid Admin";
export const SHAREPOINT_LINK = "https://orbuscompany.sharepoint.com/sites/Photos/Polaroid%20Photos/Forms/Polaroid%20Photos.aspx";
